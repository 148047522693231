import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from './Screens/LoginScreen';
import SignupScreen from './Screens/SignupScreen';
import ChatScreen from './Screens/ChatScreen';
import {store, persistor} from './redux/store'
//import {persistor} from './redux/store'
import Channels from './Screens/Channels';
import CallScreen from './Screens/CallScreen';
import { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

const Stack = createNativeStackNavigator()

export default class Application extends Component {


  render() {
    //console.log('authentication state:', store.getState().user.isLoggedin)
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer>
          {
            <Stack.Navigator >
              {/* <Stack.Screen name="Channels2" component={ChannelsScreen2} /> */}
             <Stack.Screen name="Login" component={LoginScreen} />
              <Stack.Screen name="Channels" component={Channels} />
              <Stack.Screen name="ChatScreen" component={ChatScreen} />
              <Stack.Screen name="Signup" component={SignupScreen} />
              <Stack.Screen name="Call" component={CallScreen} />
            </Stack.Navigator>
          }
        </NavigationContainer>
        </PersistGate>
      </Provider>
    );
  }

}

