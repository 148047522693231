import { ICE_CANDIDATE_RECEIVED, SDP_RECEIVED } from "../actions"

const initialState = {
    sdp: null,
    offerType: null,
    candidate: null
}

const callReducer = (state = initialState, action) => {
    switch (action.type) {
        case SDP_RECEIVED:
            state = { ...state, sdp: action.payload }
            return state
        case ICE_CANDIDATE_RECEIVED:
            state = { ...state, candidate: action.payload }
            return state
        default:
            return state
    }
}

export default callReducer