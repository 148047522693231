import {USER_LOGGEDIN, USER_LOGGEDOUT} from '../actions'

const initialState = {
    isLoggedin: false,
    token:null,
    user:{}
}

const userReducer = (state = initialState, action) =>{
    switch(action.type){
        case USER_LOGGEDIN:
            state= {...state, isLoggedin:true, token: action.payload.accessToken, user: action.payload.user }
            return state;
        default:
            return state;
    }
}

export default userReducer