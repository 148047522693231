export const USER_LOGGEDIN = 'USER_LOGGED_IN'
export const USER_LOGGEDOUT = 'USER_LOGGED_OUT'
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
export const BULK_MESSAGE_RECEIVED = 'BULK_MESSAGE_RECEIVED'
export const MESSAGE_DELETED = 'MESSAGE_DELETED'
export const ADD_CHANNEL_NAME = 'ADD_CHANNEL_NAME'
export const UPDATE_CHANNELS_WITHDATA = 'UPDATE_CHANNELS_WITHDATA'
export const SET_USERID = 'SET_USERID'
export const SET_ACTIVE_CHANNEL = 'SET_ACTIVE_CHANNEL'
export const SDP_RECEIVED = 'SDP_RECEIVED'
export const ICE_CANDIDATE_RECEIVED = 'ICE_CANDIDATE_RECEIVED'
import {store} from './store'

export const loginUser = (data) => (
    {
        type: USER_LOGGEDIN,
        payload: data
    }
)


