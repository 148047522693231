import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, StatusBar, Button, Platform } from 'react-native';
import { Camera, CameraType } from 'expo-camera';
import { connect } from 'react-redux';
import client from '../redux/featherClient'
import Util from '../lib/util';
import { RTCPeerConnection, RTCView, mediaDevices, MediaStream, RTCIceCandidate, registerGlobals } from 'react-native-webrtc-web-shim';
import GettingCall from '../comonents/gettingCall'
import Video from '../comonents/video';
import InCallManager from 'react-native-incall-manager';
//Platform.OS === 'web' && import { RTCPeerConnection , RTCView, mediaDevices} from 'react-native-webrtc-web-shim';

const configuration = {
    iceServers: [
        {
            urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
            ],
        },
    ],
    iceCandidatePoolSize: 10,
};

const CallScreen = ({ route, user, channels }) => {

    const [localStream, setLocalStream] = useState(null)
    const [remoteStream, setRemoteStream] = useState(null)
    const activeChannel = useRef(null)
    const localUser = useRef(null)
    const callType = useRef(null)
    const [offer, setOffer] = useState(null)
    const [answer, setAnswer] = useState(null)
    const incomingCall = useRef(null)
    const callerId = useRef(null)
    const calleeId = useRef(null)
    const callId = useRef(null)
    const connecting = useRef(false)
    const pc = useRef(RTCPeerConnection)
    const remoteCandidates = useRef([])
    const localIceCandidates = useRef([])

    useEffect(() => {
        activeChannel.current = route.params.channelId
        localUser.current = user.user._id
    })

    useEffect(() => {

        const subscribeUpdate = client.service('webrtc').on('patched', async signal => {
            switch (signal.updateType) {
                case 'offer':
                    console.log('offer received.')
                    await setupWebrtc()
                    setOffer(signal.offer)
                    callType.current = 'Incoming'
                    return;
                case 'answer':
                    console.log('answer received')
                    if (!pc.current.currentRemoteDescription && signal && signal.answer) {
                        //console.log('Got remote description: ', signal.answer);
                        const rtcSessionDescription = new RTCSessionDescription(signal.answer);
                        await pc.current.setRemoteDescription(rtcSessionDescription);
                    }
                    return
                case 'calleeIcecandidate':
                    console.log('callee candidate received:', signal)
                    handleRemoteCandidate(signal.calleeCandidates)
                    return
                case 'callerIcecandidate':
                    handleRemoteCandidate(signal.callerCandidates)
                    console.log('caller candidate received:', signal.callerCandidates)
                    return
            }
        })

        const subscribeCreate = client.service('webrtc').on('created', async signal => {
            console.log('call id:', signal._id)
            callId.current = signal._id
            incomingCall.current = true
            callerId.current = signal.callerId
            calleeId.current = signal.recipientId
            callType.current = 'Incoming'
        })

        const remove = client.service('webrtc').on('removed', call => {
            console.log('remove signal received:', call)
            hangup()
        })
        return () => {
            subscribeUpdate()
            subscribeCreate()
            remove()
        }
    }, [])


    let channel = _.find(channels.channels, (c) => {
        return c.channelId === route.params.channelId;
    });



    const join = async () => {
        console.log('joining the call')
        connecting.current = true
        await pc.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await pc.current.createAnswer();
        //console.log('Created answer:', answer, 'caller id is:', callerId.current);
        await pc.current.setLocalDescription(answer);
        //console.log('remote desc:', pc.current.remoteDescription)
        sendCallMessage('answer', answer)
        //processCandidates()
    }
    const hangup = async () => {
        stop()
    }


    const getCallMessage = (type, data) => {

        if (type === "localOffer") {
            return {
                offer: data,
                updateType: 'offer',
                //recipientId: "5ecf82cdc1ee05304c0b54a5",
                recipientId: activeChannel.current,
                callerId: localUser.current,
            }
        }
        if (type === "answer") {
            return {
                answer: data,
                updateType: type,
                //recipientId: "5ecf82cdc1ee05304c0b54a5",
                //recipientId: activechannel,
                recipientId: callType.current === 'Incoming' ? localUser.current : activeChannel.current,
                callerId: callType.current === 'Incoming' ? activeChannel.current : localUser.current,
            }
        }
        if (type === "icecandidate") {
            console.log('call type:', incomingCall.current)
            return {
                candidates: data,
                //recipientId: "5ecf82cdc1ee05304c0b54a5",
                recipientId: callType.current === 'Incoming' ? localUser.current : activeChannel.current,
                //callerId: gettingCall? callerId.current : user.user._id,
                callerId: callType.current === 'Incoming' ? activeChannel.current : localUser.current,
                //updateType: gettingCall? "calleeIcecandidate" : "callerIcecandidate"
                updateType: callType.current === 'Incoming' ? "calleeIcecandidate" : "callerIcecandidate"
            }
        }
    }

    const sendCallMessage = (type, data) => {
        //console.log('data to send:',type, "  : ", data)
        let msg = getCallMessage(type, data)
        console.log('sending signal:', msg.type)
        if (type === "localOffer") {
            //console.log('sending offer', getCallMessage(type, data))
            return client
                .service('webrtc').patch(callId.current, msg)
                .then((res) => {
                    //console.log('sent local offer:', res)
                })
        }

        if (type === "answer") {
            return client
                .service('webrtc').patch(callId.current, msg)
                .then((res) => {
                    //console.log('sent local answer:', res)
                })
        }

        if (type === "icecandidate") {
            if (callId.current) {
                //console.log('callId:',callId.current)
                return client.service('webrtc')
                    .patch(callId.current, msg)
                    .then((res) => {
                        //console.log(`${gettingCall} response..........`,res)
                    })
            } else {
                return client.service('webrtc')
                    .create(msg)
                    .then((res) => {
                        //console.log(`${gettingCall} response`,res)
                        callId.current = res._id
                    })
            }

        }
    }





    const stop = () => {
        incomingCall.current = false
        connecting.current = false
        remoteCandidates.current = []
        serverCleanup()
        callType.current = null
        streamCleanup()
    };

    const streamCleanup = () => {
        if (localStream) {
            localStream.getTracks().forEach(t => t.stop())
            localStream.release()
        }
        setLocalStream(null)
        setRemoteStream(null)
    }
    const serverCleanup = async () => {
        //console.log('deleting callId:', callId.current)
        return client.service('webrtc')
            .remove(callId.current)
            .then((res) => {
                console.log('server record cleared')
                callId.current = null
            })
    }

    const addEventListners = () => {
        pc.current.addEventListener('connectionstatechange', event => {
            //console.log(`Connection state change: ${pc.current.connectionState}`);
            console.log("Connectionstatechange", event)
        });
        pc.current.addEventListener('icecandidate', event => {
            if (!event.candidate) {
                console.log('Got final candidate!');
                sendCallMessage("icecandidate", localIceCandidates.current)
                return;
            }
            localIceCandidates.current.push(event.candidate)
            //handleRemoteCandidate(event.candidate)
            //sendCallMessage("icecandidate", event.candidate)
            //console.log('my ICE candidates: ', myIceCandidates)
        });
        pc.current.addEventListener('icecandidateerror', event => {
            console.log("icecandidateerror", event)
        });
        pc.current.addEventListener('iceconnectionstatechange', event => {
            console.log("iceconnectionstatechange", event)
        });
        pc.current.addEventListener('icegatheringstatechange', event => {
            console.log("icegatheringstatechange", event)
            //console.log(`ICE gathering state changed: ${pc.current.iceGatheringState}`);
        });
        pc.current.addEventListener('negotiationneeded', event => {
            console.log("negotiationneeded", event)
        });
        pc.current.addEventListener('signalingstatechange', event => {
            console.log("signalingstatechange", event)
        });
        pc.current.addEventListener('addstream', event => {
            console.log("addstream", event)
            setRemoteStream(event.stream)
        });
        pc.current.addEventListener('removestream', event => {
            console.log("removestream", event)
        });
    }


    function handleRemoteCandidate(icecandidate) {
        //console.log("handleRemoteCandidate:", remoteCandidates)
        console.log("incoming candidates:", icecandidate)
        if (pc.current.remoteDescription != null) {
            icecandidate.map(candidate => {
                let iceCandidate = new RTCIceCandidate(candidate);
                pc.current.addIceCandidate(iceCandidate)
            })
        } else {
            remoteCandidates.current = icecandidate
        }
    };

    function processCandidates() {
        console.log('processCandidates(): ', remoteCandidates.current)
        if (remoteCandidates.current.length < 1 || pc.current.remoteDescription === null) { return; }
        else {
            remoteCandidates.current.map(candidate => {
                console.log('adding candidate from saved array')
                pc.current.addIceCandidate(candidate)
            });
            remoteCandidates.current = [];
        }
    };

    const create = async () => {
        console.log('calling...')
        let sessionConstraints = {
            mandatory: {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true,
                VoiceActivityDetection: true
            }
        };
        try {
            const offerDescription = await pc.current.createOffer(sessionConstraints)
            await pc.current.setLocalDescription(offerDescription)
            sendCallMessage("localOffer", offerDescription)
        } catch (err) {
            console.log("error:", err)
        }
    }

    const setupWebrtc = async () => {
        pc.current = new RTCPeerConnection({
            iceServers: [
                {
                    urls: [
                        'stun:stun1.l.google.com:19302',
                        'stun:stun2.l.google.com:19302',
                    ],
                },
            ],
            iceCandidatePoolSize: 10,
        })
        addEventListners()
        const stream = await Util.getStream()
        if (stream) {
            setLocalStream(stream)
            pc.current.addStream(stream)
        }
    }

    const initServerCall = async () => {
        return client.service('webrtc').create({
            recipientId: activeChannel.current,
            callerId: localUser.current,
            callerCandidates: [],
            calleeCandidates: []
        })
            .then(res => {
                console.log("call created:", res)
                callId.current = res._id
            })
    }

    const start = async () => {
        connecting.current = true
        callType.current = 'Outgoing'
        await initServerCall()
        await setupWebrtc()
        await create()
    };

    if (callType && callType.current === 'Incoming' && !connecting.current) {
        return (
            <GettingCall join={join} hangup={hangup} />
        )
    }
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <Video
                hangup={hangup}
                localStream={localStream}
                remoteStream={remoteStream}
                startCall={start}
                stopCall={stop}
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    camera: {
        //flex: 1,
        maxHeight: "200px",
        width: "200px"
    }
});

const mapStateToProps = (state) => ({
    user: state.user,
    channels: state.channels,
    call: state.call
})

export default connect(mapStateToProps)(CallScreen);
