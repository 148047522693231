import { useState, useCallback, useLayoutEffect, useEffect } from "react";
import { View, StatusBar, StyleSheet, TouchableOpacity, Platform } from "react-native"
import { Badge, Box, FlatList, Heading, Avatar, HStack, VStack, Text, Spacer, Center, NativeBaseProvider, Flex } from "native-base";
import { connect } from "react-redux";
import Chat from '../comonents/chat'
import { store } from '../redux/store'
import { SET_ACTIVE_CHANNEL } from "../redux/actions";
import { Entypo } from '@expo/vector-icons';
import { getFormattedDate } from "../lib/time";


const Channels = ({ navigation, channels, user }) => {
  console.log('channels in channelsScreen:', channels)
  const [text, setText] = useState("");
  const [activeChannelId, setActiveChannelId] = useState("")
  const [activeChannelName, setActiveChannelName] = useState("")
  const [activeChannelType, setActiveChannelType] = useState("")

  const onpress = (id) => {
    store.dispatch({ type: SET_ACTIVE_CHANNEL, payload: id })
    if (Platform.OS === 'web') {
      setActiveChannelId(id)
      setChannelParams(id)
      setNavOptions()
    } else {
      navigation.navigate('ChatScreen', { channelId: id })
    }
  }
  useState(() => {

    if (activeChannelId === '' || activeChannelId === undefined) {
      setActiveChannelId(channels?.channels[0]?.channelId)
      setActiveChannelName(channels?.channels[0]?.name)
      setActiveChannelType(channels?.channels[0]?.type)
    } else {
      let channel = _.find(channels.channels, (c) => {
        return c.channelId === activeChannelId;
      })
      setActiveChannelName(channel.name)
      setActiveChannelType(channel.type)
    }
  })

  const setChannelParams = (id) => {
    if (activeChannelId === '' || activeChannelId === undefined) {
      console.log('i am here')
      setActiveChannelId(channels?.channels[0]?.channelId)
      setActiveChannelName(channels?.channels[0]?.name)
      setActiveChannelType(channels?.channels[0]?.type)
    } else {
      let channel = _.find(channels.channels, (c) => {
        return c.channelId === id;
      })
      setActiveChannelName(channel.name)
      setActiveChannelType(channel.type)
    }
  }

  const RenderChannelList = ({ data }) => {

    return (
      <Box borderRightWidth="1" borderColor="coolGray.200" height="100%">
        {/* <Heading fontSize="xl" p="4" pb="3">
        Chats
      </Heading> */}
        <FlatList data={data} renderItem={({ item }) =>
          <TouchableOpacity
            onPress={() => onpress(item.channelId)}
          >
            <Box
              borderColor="coolGray.200"
              borderBottomWidth="1" _dark={{ borderColor: "gray.600" }}
              pl="4" pr="5" py="2">
              <HStack space={3} justifyContent="space-between">
                <Avatar size="48px" source={{
                  uri: item.avatar
                }} />
                <VStack>
                  <Text _dark={{
                    color: "warmGray.50"
                  }} color="coolGray.800" bold>
                    {item.name}
                  </Text>
                  <Text color="coolGray.600" _dark={{
                    color: "warmGray.200"
                  }}>
                    {item.lastMessageText}
                  </Text>
                </VStack>
                <Spacer />
                <VStack>
                  <Text fontSize="xs" _dark={{
                    color: "warmGray.50"
                  }} color="coolGray.800" alignSelf="flex-start">
                    {getFormattedDate(item.lastMessageTime)}
                  </Text>
                  {item.unreadCount > 0 && <Badge // bg="red.400"
                    colorScheme="success" rounded="full" variant="solid" alignSelf="flex-end" _text={{
                      fontSize: 12
                    }}>
                    {item.unreadCount}
                  </Badge>}
                </VStack>
              </HStack>
            </Box>
          </TouchableOpacity>}

          keyExtractor={item => item.channelId}
        />
      </Box>
    )
  }
  useEffect(() => {
    setNavOptions()
  })

  function HeaderTitle() {
    return (
      <NativeBaseProvider>
        <Box pl="5" pr="5" py="2">
          <HStack space={3} justifyContent="space-between" alignItems="center">
            <Avatar size="48px" source={{
              uri: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLHzLEwQx65NMYBnJJYTM3SXVnoSxLLVfyFVoZoNyc_xbGURsLGigl6bqn00E8AL8Q0fg&usqp=CAU"
            }} />
            <Text>{user.user.name}</Text>
            <TouchableOpacity style={{marginLeft:150}}>
            <Entypo name="dots-three-vertical" size={15} color="black" />
            </TouchableOpacity>
          </HStack>
        </Box>
      </NativeBaseProvider>
    );
  }
  const setNavOptions = () => {
    navigation.setOptions({
      headerTitle: () => (<HeaderTitle />),
      headerRight: () => (

        <NativeBaseProvider>
           <Box pl="4" pr="5" py="4">
          <HStack space={3} justifyContent="space-between" alignItems="center">
            <Text>{activeChannelName}</Text>
            <Avatar size="36px" source={{
              uri: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLHzLEwQx65NMYBnJJYTM3SXVnoSxLLVfyFVoZoNyc_xbGURsLGigl6bqn00E8AL8Q0fg&usqp=CAU"
            }} />
          </HStack>
        </Box>
        </NativeBaseProvider>
      )
    })
  }

  return (
    <NativeBaseProvider>
      {Platform.OS === 'web' ?
        <View style={styles.container}>
          <View style={styles.leftSide}>
            <RenderChannelList data={channels.channels} />
          </View>
          <View style={styles.rightSide}>
            {/* <RenderMessages channels={channels} /> */}
            <Chat cid={activeChannelId} />
          </View>
        </View> :
        <RenderChannelList data={channels.channels} channelId={activeChannelId} />
      }

    </NativeBaseProvider>
  );
}

const mapStateToProps = state => ({
  channels: state.channels,
  user: state.user
})
export default connect(mapStateToProps)(Channels);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
    justifyContent: 'center',
    flexDirection: 'row',
    //margin: '20px'
  },
  item: {
    backgroundColor: '#f9c2ff',
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    width: 400,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 32,
  },
  leftSide: {
    flex: 1,
    //borderColor: "coolGray.200",
    //borderRightWidth: '.01px',
    minHeight: '100%',
    height: '100%'
  },
  rightSide: {
    flex: 2.5,
    minHeight: '80%',
    //marginLeft: '20px'
  }
});