import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
// import { initializeApp } from 'firebase/app';
// import { getFirebaseConfig } from '../firebase';
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { connect } from 'react-redux';
import {store} from '../redux/store'
import { USER_LOGGEDIN } from '../redux/actions';
//import feathers from '../redux/feathers'
import client from '../redux/featherClient'

//const firebaseApp = initializeApp(getFirebaseConfig());



const LoginScreen = ({ navigation, auth, user }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    useEffect(() => {
        if(auth){
            //console.log('auth:',auth)
            navigation.replace('Channels')
        }
    })
    console.log('user in login:', user)
    function login() {
        //     if (typeof window.localStorage !== 'undefined') {
        //       console.log('storage exists',window.localStorage)
        //   } else {
        //     console.log('no storage exists')
        //       // localStorage not defined
        //   }
            client
              .authenticate({
                strategy: 'local',
                phone:username,
                password: password,
              }).
              then(resp =>{
                console.log("resp:",resp)
                store.dispatch({type: USER_LOGGEDIN, payload: resp})
              }) 
              .catch(err => console.log('error',err));
          }
    // const signinFirebase = () => {
    //     const auth = getAuth();
    //     signInWithEmailAndPassword(auth, username, password)
    //         .then((userCredential) => {
    //             // Signed in 
    //             const user = userCredential.user;
    //             navigation.replace('Chat')
    //             // ...
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             alert(error)
    //         });

    // } 

    return (
        <View style={style.container}>
            <TextInput
                style={style.input}
                onChangeText={setUsername}
                value={username}
                placeholder="Username"
            />
            <TextInput
                style={style.input}
                onChangeText={setPassword}
                value={password}
                placeholder="Password"
                secureTextEntry
            />
            <TouchableOpacity
                style={style.button}
                onPress={() => login()}
            >
                <Text style={{ color: 'white' }}>Login</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={style.button}
                //onPress={() => navigation.navigate('Signup')}
            >
                <Text style={{ color: 'white' }}>Signup</Text>
            </TouchableOpacity>
        </View>
    )
}

const mapStateToProps = state => (
    {
        auth: state.user.isLoggedin,
        user: state.user
    }
)

export default connect(mapStateToProps)(LoginScreen);

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        padding: 10
    },
    button: {
        width: 200,
        marginTop: 10,
        padding: 10,
        alignItems: 'center',
        backgroundColor: "#841584"
    },
    input: {
        height: 40,
        width: 200,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    }
})