import _ from 'lodash'
import { MESSAGE_RECEIVED, MESSAGE_DELETED, ADD_CHANNEL_NAME, UPDATE_CHANNELS_WITHDATA, USER_LOGGEDIN, SET_ACTIVE_CHANNEL } from "../actions"
const PLACEHOLDER = 'https://placeimg.com/140/140/any';

const initialState = {
  channels: [],
  userId: "",
  activeChannelId:"",
  lastMessageReceivedAt: ""
}

const channelReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_RECEIVED:
      state = { ...state, channels: addNewChannelByMsg(state.channels, action.payload, state.userId), lastMessageReceivedAt: action.payload.createdAt } //messages: [formatMessage(action.payload), ...state.messages]
      return state
    case ADD_CHANNEL_NAME:
      state = { ...state, channels: addNewChannel(state.channels, action.payload) }
      return state
    case USER_LOGGEDIN:
      state = { ...state, userId: action.payload.user._id }
      return state
    case SET_ACTIVE_CHANNEL:
      state = {...state, channels: setActiveChannel(state.channels, action.payload)}
      return state
    default:
      return state
  }
}

export default channelReducer

const setActiveChannel = (channels, activeChannelId) => {
  let preActive = _.find(channels, (c) => {
    return c.isActiveChannel === true
  })
  if(preActive) preActive.isActiveChannel = false
  let channel = _.find(channels, (c) => {
    return c.channelId === activeChannelId
  })
  channel.isActiveChannel = true
  channel.unreadCount = 0
  return channels
}

function formatMessage(message, userId) {
  console.log('message to format:', message)
  return {
    _id: message._id,
    text: message.text,
    createdAt: new Date(message.createdAt),
    user: {
      _id: message.sender.userId,
      name: message.sender.name,
      avatar: message.sender.avatar ? message.sender.avatar : PLACEHOLDER,
    },
    channel: {
      //name: message.hasOwnProperty('channel') ? message.channel.name : message.sender.name,
      //channelId: message.hasOwnProperty('channel') ? message.channel._id : message.sender.userId
      //
      name: message.hasOwnProperty('channel') ? message.channel.name : (message.sender.userId === userId) ? message.recipient.name : message.sender.name,
      channelId: message.hasOwnProperty('channel') ? message.channel._id : (message.sender.userId === userId) ? message.recipient._id : message.sender.userId,
    }
  };
}

const formatChannel = (msg, userId) => {
  console.log('msg in quest:',msg)
  console.log('userId:',userId)
  return {
    name: msg.hasOwnProperty('channel') ? msg.channel.name : (msg.sender.userId === userId) ? msg.recipient.name : msg.sender.name,
    channelId: msg.hasOwnProperty('channel') ? msg.channel._id : (msg.sender.userId === userId) ? msg.recipient._id : msg.sender.userId,
    type: msg.hasOwnProperty('channel') ? 'group' : 'individual',
    avatar: PLACEHOLDER,
    messages: []
  }
}

const addNewChannel = (currentChannels, newChannel) => {
  //console.log('newchannel:',newChannel)
  //console.log('existing:',currentChannels)
  let ifAlreadyExists = _.find(currentChannels, (c) => {
    return c.channelId === newChannel.channelId;
  });
  if (!ifAlreadyExists) {
    currentChannels.push(newChannel);
  }
  return currentChannels
};
const addNewChannelByMsg = (channels, msg, userId) => {
  let m = formatMessage(msg, userId)
  console.log('reduder formated msg:',m)

  let c = formatChannel(msg, userId)
  let ifExists = _.find(channels, (ch) => {
    return ch.channelId === c.channelId
  })
  if (ifExists) {
    //console.log('exists:', ifExists)
    addMessage(m, channels)
  } else {
    //console.log('new channel: push it')
    channels.push(c)
    //console.log('updated channnels:',channels)
    addMessage(m, channels)
  }
  channels.sort((a,b)=>{
    return new Date(b.lastMessageTime) - new Date(a.lastMessageTime);
  })
  return channels
}

const addMessage = (msg, channels) => {
  //addNewChannel(msg)
  let currChannel = _.find(channels, (c) => {
    return c.channelId === msg.channel.channelId || c.channelId === msg.user._id
  })
  if (currChannel) {
    //check if its a duplicate message
    let msgExists = _.find(currChannel.messages, (m) => {
      return m._id === msg._id
    })
    if (msgExists) {
      return channels
    } else {
      currChannel.messages.unshift(msg)
      currChannel.lastMessageTime = msg.createdAt
      currChannel.lastMessageText = msg.text
      if(!currChannel.isActiveChannel) {
        currChannel.unreadCount ? currChannel.unreadCount++ : currChannel.unreadCount = 1
      } 
    }

  } else {

    // console.log('channel not found')
  }
  //console.log('updated channels in addMssage Reducer:',channels)
  return channels
}