import React, { Component } from 'react';
import { View, Text , StyleSheet, Image} from 'react-native';
import Button from './button';

const GettingCall = (props)=> {
    return (
      <View style={styles.container}>
        <Image source={require('../images/callerImg.png')} style={styles.Image}/>
        <View style={styles.bContainer}>
        <Button 
          iconName="phone"
          backgroundColor="green"
          onPress={props.join}
          style={{marginRight: 30}}
        />
        <Button 
          iconName="phone"
          backgroundColor="red"
          onPress={props.hangup}
          style={{marginLeft: 30}}
        />
        </View>
      </View>
    )
}

export default GettingCall

const styles =  StyleSheet.create({
  container:{
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor:'#fff'
  },
  Image: {
    position:'absolute',
    height: '100%',
    width: '100%'
  },
  bContainer:{
    flexDirection:'row',
    bottom: 30
  }

})