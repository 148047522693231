import React, { useLayoutEffect, useState, useCallback, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import { getAuth, signOut } from "firebase/auth";
import { MaterialIcons } from '@expo/vector-icons';
import { GiftedChat } from 'react-native-gifted-chat';
import { connect } from 'react-redux';
import client from '../redux/featherClient'
import _ from 'lodash'
import { USER_LOGGEDIN } from '../redux/actions';
import { Entypo } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const Chat = ({ channels, auth, cid, timeOfLastMessage }) => {

  const navigation = useNavigation()
  const [text, setText] = useState("");
  const [activeChannelType, setActiveChannelType] = useState("")
  const [activeChannelName, setActiveChannelName] = useState("")
  const [activeChannelId, setActiveChannelId] = useState("")

  //console.log('channels in chat :', timeOfLastMessage)

  //const { width, height } = Dimensions.get('window')

  let chats = _.find(channels.channels, (c) => {
    return c.channelId === cid;
  });

  useEffect(() => {
    setActiveChannelId(chats?.channelId)
    setActiveChannelName(chats?.name)
    setActiveChannelType(chats?.type)
    console.log('active name:', activeChannelName)

  })

  const getMessage = (text) => {
    if (activeChannelType === 'individual')
      return {
        text, recipientId: activeChannelId, "recipient": {
          "name": activeChannelName,
          "_id": activeChannelId
        },
      }
    else return {
      text, channelId: activeChannelId, "channel": {
        "name": activeChannelName,
        "_id": activeChannelId
      },
    }
  }


  const sendMessage = () => {
    console.log('SENDING MSG: ', getMessage(text))
    if (text) {
      return client
        .service('messages')
        .create(getMessage(text))
        .then((res) => {
          console.log('message sent',res)
        });
    }
    //ev.preventDefault();
  }
  const renderActions = () => {
    return (
      <View style={{ marginLeft: 10, display: 'flex', alignSelf: 'center', flexDirection: 'row', padding: 5 }}>
        <TouchableOpacity
          //onPress={()=>{navigation.navigate('Call')}}
        >
          <Entypo name="emoji-happy" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={()=>{navigation.navigate('Call',{channelId:cid})}}
        >
          <Ionicons name="call" size={24} color="black" />
        </TouchableOpacity>
      </View>
    )
  }

  const onSend = useCallback((messages = []) => {
    setMessages(previousMessages => GiftedChat.append(previousMessages, messages))
  }, [])
  return (

    <GiftedChat
      messages={chats?.messages}
      renderActions={renderActions}
      onInputTextChanged={setText}
      onSend={() => sendMessage()}
      user={{
        _id: auth.user._id,
      }}
    />

  );
}

const mapStateToProps = state => ({
  auth: state.user,
  channels: state.channels,
  timeOfLastMessage: state.channels.lastMessageReceivedAt
})

export default connect(mapStateToProps)(Chat)
