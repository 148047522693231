import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
//import localstorage from 'feathers-localstorage';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { store } from './store'
import { MESSAGE_RECEIVED, USER_LOGGEDIN, USER_LOGGEDOUT, ADD_CHANNEL_NAME, UPDATE_CHANNELS_WITHDATA, SDP_RECEIVED, ICE_CANDIDATE_RECEIVED } from './actions';
import { sortedIndex } from 'lodash';

const options = { transports: ['websocket'], pingTimeout: 3000, pingInterval: 7000 };
const socket = io('https://api.withafterschool.com', options);
//const socket = io('http://localhost:3031', options);

const client = feathers();
client.configure(socketio(socket));
client.configure(auth({
  storage: AsyncStorage // To store our accessToken
}));

const channelService = client.service('channelsubscriptions');
const messagesService = client.service('messages');


client.service('messages').on('created', createdMessage => {
  console.log('message received:', createdMessage)
  if (createdMessage.type === 'call') {
    switch (createdMessage.subType) {
      case "icecandidate":
        store.dispatch({type: ICE_CANDIDATE_RECEIVED, payload: createdMessage.payload})
        return;
      case "sdp":
        store.dispatch({type: SDP_RECEIVED, payload: createdMessage.payload})
        return;

    }
  } else {
    store.dispatch({ type: MESSAGE_RECEIVED, payload: createdMessage })
  }

});
//
client.io.on('connect', () => {
  authenticate().then(() => {
    console.log('client authenticated after reconnection');
  }).catch(error => {
    console.log('error authenticating after reconnection', error);
  });
});

client.io.on('disconnect', () => {
  console.log('disconnected');
});


const authenticate = (options) => {
  options = options ? options : undefined;
  return _authenticate(options).then(payload => {
    console.log('client authenticated successfully', payload.user._id, payload.user.email);
    //this.user = payload.user;
    //this.isAuthenticated = true;
    store.dispatch({ type: USER_LOGGEDIN, payload: payload })
    return Promise.resolve(payload.user);
  }).catch(error => {
    console.log('authenticated failed', error.message);
    console.log(error);
    return Promise.reject(error);
  });
}

const _authenticate = (payload) => {
  return client.authenticate(payload)
    .then(response => {
      console.log('signin resp:', response)
      // return this.app.passport.verifyJWT(response.accessToken);
      return response
    })
    .then(payload => {
      //return this.app.service('users').get(payload.user._id);
      return payload
    }).catch(e => Promise.reject(e));
}
client.on('authenticated', loginResult => {
  console.log("login Result:", loginResult)

  Promise.resolve(
    channelService.find({ query: { userId: loginResult?.user?._id } })
  ).then(subsdata => {
    const chs = subsdata.data[0]?.subscriptions
    console.log('subsdata:', chs)
    let i;
    for (i = 0; i < chs?.length; i++) {
      console.log('dispatching channel name:', chs[i])
      store.dispatch({ type: ADD_CHANNEL_NAME, payload: formatGroupChannel(chs[i]) })
    }
    const groups = subsdata.data[0]?.subscriptions.map(s => {
      return s.channelId
    })
    var d = new Date();
    Promise.all([
      messagesService.find({
        query: {
          $sort: { createdAt: 1 },
          $limit: 100,
          $or: [
            { recipientId: loginResult?.user?._id }, //my id
            { senderId: loginResult?.user?._id },
            { channelId: { $in: groups } }
          ],
          createdAt: {
            $gt: store.getState().channels?.lastMessageReceivedAt ? store.getState().channels.lastMessageReceivedAt : d.setDate(d.getDate() - 10)
          }
        },
      }),
      //usersService.find(),
      //channelService.find({ query: { userId: loginResult?.user?._id } }),
    ]).then(([messagePage, userPage, channelsPage]) => {

      let allMessages = messagePage.data
      //console.log(allMessages.length, " messages received")
      //console.log('all messages:',allMessages)
      let i;
      for (i = 0; i < allMessages.length; i++) {
        store.dispatch({ type: MESSAGE_RECEIVED, payload: allMessages[i] })
      }

      //let channelGroups =  store.getState().channels.channels

      //console.log()
      // We want the latest messages but in the reversed order
      // const messagesResult = messagePage?.data?.reverse();
      //const usersResult = userPage.data;

      // console.log("channelsPage ", channelsPage);

      // const subs = channelsPage.data[0]?.subscriptions;

      // const channelGroups = subs.map(sb => {
      //   return {
      //     name: sb.name,
      //     channelId: sb.channelId,
      //     type: 'group',
      //     messages: []
      //   }
      // })

      //const allChannels = checkIfChannelsExistsInMessagesAndLocalstate( messagePage.data , channelGroups , loginResult?.user?._id );
      //console.log('all channels with msgs', allChannels)
      //store.dispatch({type: UPDATE_CHANNELS_WITHDATA, payload: allChannels })
      // setChannels(allChannels)

      // Once both return, update the state
      //setLogin(loginResult);
      //setUsers(usersResult);
    });
  });
})


export default client;

const formatGroupChannel = (ch) => {
  return {
    name: ch.name,
    channelId: ch.channelId,
    type: 'group',
    messages: []
  }
}

const groupChannels = (data, userId) => {
  const channels = [];

  for (let i = 0; i < data?.length; i++) {
    const msg = data[i];
    let channel = "";

    channel = channels.find(ch => ((ch.channelId === msg.senderId || ch.channelId === msg.recipientId) && !msg.hasOwnProperty('channel') || ch.channelId === msg?.channel?._id));

    if (channel) {
      if (msg.senderId === userId) {
        msg.party = "me";
      } else {
        msg.party = "other";
      }
      channel.messages.push(msg);
    }
    else {
      if (msg.senderId !== userId || msg.hasOwnProperty('channel')) {

        console.log('Here i Am')
        msg.party = 'other';
        const messages = [msg];

        channels.push({
          name: msg.hasOwnProperty('channel') ? msg.channel.name : msg.sender.name,
          messages,
          channelId: msg.hasOwnProperty('channel') ? msg.channel._id : msg.senderId,
          type: msg.hasOwnProperty('channel') ? 'group' : 'person'
        });
      }
    }
  }
  return channels
}
const checkIfChannelsExistsInMessagesAndLocalstate = (messages, channelGroups, userId) => {
  let allChannels = [];
  const storedChannels = store.getState().channels.channels

  //const storedChannels = localStorage.getItem('sch-messages') || "";

  //  console.log('messages live' , messages )

  // if (messages.length === 0) {
  //     if (storedChannels) {
  //         return (JSON.parse(localStorage.getItem('sch-messages')));
  //     }
  // }
  const channelsWithMsgs = groupChannels(messages, userId);

  allChannels = channelsWithMsgs;


  // Check in messages if channel exists .
  for (let i = 0; i < channelGroups.length; i++) {
    const channel = channelsWithMsgs.find(ch => ch?.channelId === channelGroups[i]?.channelId);

    if (!channel) {
      allChannels.push(channelGroups[i]);
    }
  }



  // Check in localstorage if channel exists
  if (storedChannels) {
    //const parsedChannels = JSON.parse(localStorage.getItem('sch-messages'));
    console.log('channelsWithMsgs: ', storedChannels);


    for (let i = 0; i < storedChannels.length; i++) {
      const channel = channelsWithMsgs.find(ch => ch?.channelId === storedChannels[i]?.channelId);

      if (!channel) {
        console.log('here', channel)
        allChannels.push(channelsWithMsgs[i]);
      }
    }
  }


  //localStorage.setItem('sch-messages', JSON.stringify(allChannels));

  return allChannels;
}

const processMessage = (message, userId) => {

  const stored = store.getState().channels.channelsWithData
  if (message.senderId !== userId) {
    message.status = 'unread';
  } else {
    message.status = 'read';
  }

  if (stored && message) {

    let updateChannel = stored

    const channelMsg = updateChannel?.find(v => ((v.channelId === message?.senderId || v.channelId === message?.recipientId)
      && !message.hasOwnProperty('channel')) || v.channelId === message?.channel?._id)

    if (channelMsg) {
      console.log('here i am 1')
      const ifMsg = channelMsg.messages.some(msg => msg._id === message._id);

      if (!ifMsg) {

        if (message.senderId === userId) {
          message.party = "me";
        } else {
          message.party = "other";
        }
        channelMsg.messages.push(message);
        updateChannel = updateChannel.filter(ch => ch.channelId !== channelMsg.channelId);

        updateChannel.unshift(channelMsg);
        localStorage.setItem('sch-messages', JSON.stringify(updateChannel))
        return updateChannel;
      }
    }
    else if (message.senderId !== userId || message.hasOwnProperty('channel')) {
      console.log('i am here')
      message.party = "other";

      const newChannel = {
        name: message.hasOwnProperty('channel') ? message.channel.name : message.sender.name,
        messages: [message],
        channelId: message.hasOwnProperty('channel') ? message.channel._id : message.senderId,
        type: message.hasOwnProperty('channel') ? 'group' : 'person'
      }
      updateChannel.unshift(newChannel);
      localStorage.setItem('sch-messages', JSON.stringify(updateChannel))
      return updateChannel;
    }

  }
  else if (message && message.senderId !== userId || message.hasOwnProperty('channel')) {
    console.log('here 2')
    message.party = "other";

    const newChannel = {
      name: message.hasOwnProperty('channel') ? message.channel.name : message.sender.name,
      messages: [message],
      channelId: message.hasOwnProperty('channel') ? message.channel._id : message.senderId,
      type: message.hasOwnProperty('channel') ? 'group' : 'person'
    }
    const cnewChannels = [newChannel]
    //localStorage.setItem('sch-messages', JSON.stringify(cnewChannels))
    return newChannel;
  }
}

export function changeChannelMessageStatus(channel) {
  let chCopy = _.cloneDeep(channel);
  chCopy = {
    ...chCopy,
    messages: chCopy.messages.map(msg => { return { ...msg, status: 'read' } })
  }
  return chCopy;
}
export function updateChannelLocal(channel) {
  const chCopy = _.cloneDeep(channel);
  const storedChannels = localStorage.getItem('sch-messages') || "";

  if (storedChannels) {
    const parsedChannels = JSON.parse(localStorage.getItem('sch-messages'));
    const findChannel = parsedChannels.find(p => p.channelId === chCopy.channelId);
    findChannel.messages = chCopy.messages;

    localStorage.setItem('sch-messages', JSON.stringify(parsedChannels));
    return parsedChannels;
  }

}
export function getUnReadCount(messages) {
  const count = messages?.filter(p => p.status === 'unread')?.length;
  console.log('count', count, messages);
  return count;
}
