import React, { PureComponent, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { RTCView } from 'react-native-webrtc-web-shim';
import Button from './button';

const Video = (props) => {

    console.log('props:', props)
    
    useEffect(()=>{
        if(props.remoteStream){
            console.log('remote tracks:',props.remoteStream.getTracks())
        }
    })

    const ButtonContainer = (props) => (
        <View style={styles.bContainer}>
            <Button
                iconName="phone"
                backgroundColor={props.backgroundColor}
                onPress={props.onPress}
            />
            <Button
                iconName="microphone"
                backgroundColor={props.backgroundColor}
            //onPress={props.onPress}
            />
        </View>
    )

    if (props.localStream && !props.remoteStream) {
        return (
            <View style={styles.container}>
                <RTCView stream={props.localStream} style={styles.video} objectFit={'cover'} />
                <ButtonContainer onPress={props.hangup} />
            </View>
        )
    }
    if (props.localStream && props.remoteStream) {
        return (
            <View style={styles.container}>
                {/* //<View style={styles.leftSide}> */}
                    <RTCView stream={props.localStream} style={styles.localVideo} objectFit={'cover'} />
                {/* </View>
                <View> */}
                    <RTCView stream={props.remoteStream} style={styles.video} objectFit={'cover'}/>
                    <ButtonContainer onPress={props.hangup} backgroundColor="red" />
                {/* </View> */}



            </View>
        )
    }
    return (
        <View style={styles.container}>
            <ButtonContainer onPress={props.startCall} backgroundColor="green" />
        </View>
    )
}

export default Video

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection:'row'
    },
    bContainer: {
        flexDirection: 'row',
        bottom: 30,
        //alignSelf: 'flex-end',
       // alignContent:'flex-end',
        alignItems: 'center',
        justifyContent: 'center',
        flex:1
    },
    video: {
        //position: 'absolute',
        height: '100%',
        width: '100%',
        borderWidth:5,
        backgroundColor: 'red',
        padding:5,
        //flex: 1
    },
    localVideo: {
        //position: 'absolute',
        height: 90,
        width: 90,
        borderWidth:2,
        // top: 0,
        // left: 20,
        // elevation: 10
    },
    leftSide: {
        flex: 1,
        //borderColor: "coolGray.200",
        //borderRightWidth: '.01px',
        minHeight: '100%',
        height: '100%'
    },
    rightSide: {
        flex: 2.5,
        minHeight: '80%',
        //marginLeft: '20px'
    }
});