import {mediaDevices} from 'react-native-webrtc-web-shim'
export default class Util {
    static async getStream(){
        let isFront = true
        const soureInfos = await mediaDevices.enumerateDevices()
        console.log(soureInfos)
        let videoSourceId
        let speakerSourceId
        for(let i=0; i<soureInfos.length; i++){
            let sourceInfo = soureInfos[i]
            if(sourceInfo.kind == 'videoinput' && soureInfos.facing == (isFront ? "front" : "environment")){
                videoSourceId = sourceInfo.deviceId
            }
            if(sourceInfo.kind == 'audiooutput'){
                speakerSourceId = sourceInfo.deviceId
            }
        }
        console.log('speaker id:',speakerSourceId)
        // const stream = await navigator.mediaDevices.getUserMedia(
        //     {video: true, audio: true});
        const stream = await mediaDevices.getUserMedia({
            audio: true,
            video: {
                //width:640,
                //height: 480,
                frameRate: 30,
                facingMode: (isFront ? "user" : "environment"),
                deviceId: videoSourceId
            }
        })
        console.log('stream generarted:', stream)
        let track = stream.getTracks()
        console.log('tracks:', track)
        track.forEach(tra =>{
            //console.log('const:', tra.getConstraints())
            //console.log('capa',tra.getCapabilities())
        })


        if(typeof stream != 'boolean') return stream
        return null
    }
}