import {combineReducers} from 'redux'

import  userReducer from './reducers/userReducer'
import channelReducer from './reducers/channelsReducer'
import callReducer from './reducers/callReducer'

export default combineReducers({
    user: userReducer,
    channels: channelReducer,
    call: callReducer
})