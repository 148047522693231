import moment from 'moment';

const isToday = (date) => {
    let theDate = typeof (date) === Object ? date : new Date(date)
    const today = new Date()
    return theDate.getDate() == today.getDate() &&
        theDate.getMonth() == today.getMonth() &&
        theDate.getFullYear() == today.getFullYear()
}

const isYesterday = (date) => {
    const yesterday = new Date();
    let theDate = typeof (date) === Object ? date : new Date(date)
    yesterday.setDate(yesterday.getDate() - 1);

    if (yesterday.toDateString() === theDate.toDateString()) {
        return true;
    }
    return false;
}

export const getFormattedDate = (date) => {
    console.log('incoming date:',date)
    let theDate = typeof (date) === Object ? date : new Date(date)
    if (isToday(date)) {
        return moment(date).format('h:mm a')
    }
    if (isYesterday(date)) {
        return 'yesterday'
    }
    const cutOffDate = new Date();

    cutOffDate.setDate(cutOffDate.getDate() - 7)
    //console.log('cutoff ',cutOffDate);
    if (theDate > cutOffDate) {
        return moment().format('ddd');
    } else {
        return moment(theDate).format('M/D/YYYY')
    }
}
