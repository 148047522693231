import React, { useLayoutEffect, useState, useCallback, useEffect } from 'react';
import { View, Text, TouchableOpacity } from "react-native";
import { getAuth, signOut } from "firebase/auth";
import { MaterialIcons } from '@expo/vector-icons';
import { GiftedChat } from 'react-native-gifted-chat';
import { connect } from 'react-redux';
import client from '../redux/featherClient'
import _ from 'lodash'
import Chat from '../comonents/chat';
import dayjs from 'dayjs';

// const Chat = ({route, navigation}) => {
//   return(
//     <View>
//       <Text>This is chat screen: {route.params.channelId}</Text>
//     </View>
//   )
// }

const ChatScreen = ({ route, navigation, auth, channels }) => {
  const [text, setText] = useState("");
  const [activeChannelType, setActiveChannelType] = useState("")
  const [activeChannelName, setActiveChannelName] = useState("")
  const [activeChannelId, setActiveChannelId] = useState("")

  //console.log('channels in chat :',text)

  let chats = _.find(channels.channels, (c) => {
    return c.channelId === route.params.channelId;
  });

 
    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View>
                    <TouchableOpacity
                       // onPress={()=>signout()}
                    >
                        <MaterialIcons name="logout" size={24} color="black" />
                    </TouchableOpacity>
                </View>
            )
        })
    })
    const [messages, setMessages] = useState([]);

    useEffect(() => {
    setActiveChannelId(chats.channelId)
      setActiveChannelName(chats.name)
      setActiveChannelType(chats.type)
    }, [])

    const getMessage = (text) => {
      if (activeChannelType === 'individual')
        return {
          text, recipientId: activeChannelId, "recipient": {
            "name": activeChannelName,
            "_id": activeChannelId
          },
        }
      else return {
        text, channelId: activeChannelId, "channel": {
          "name": activeChannelName,
          "_id": activeChannelId
        },
      }
    }

    const sendMessage = () => {
      console.log('SENDING MSG: ',getMessage(text))
      if (text) {
        return client
          .service('messages')
          .create(getMessage(text))
          .then(() => {
            console.log('message sent')
          });
      }
      ev.preventDefault();
    }
  
    const onSend = useCallback((messages = []) => {
      setMessages(previousMessages => GiftedChat.append(previousMessages, messages))
    }, [])
    return (
      <Chat cid={activeChannelId} />
        // <GiftedChat
        // messages={chats.messages}
        // onInputTextChanged = {setText}
        // onSend={()=>sendMessage()}
        // user={{
        //   _id: auth.user._id,
        // }}
      ///>
    );
}

const mapStateToProps = state => ({
  auth: state.user,
  channels: state.channels
})

export default connect(mapStateToProps)(ChatScreen);
//export default Chat;