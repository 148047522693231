import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { getFirebaseConfig } from '../firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

//const firebaseApp = initializeApp(getFirebaseConfig());


const SignupScreen = ({ navigation }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [avatar, setAvatar] = useState("");
    const [password, setPassword] = useState("");


    const register = () => {
        createUserWithEmailAndPassword(getAuth(), email,password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            updateProfile(getAuth().currentUser, {
                displayName: name, 
                avatar: avatar? avatar: "https://example.com/jane-q-user/profile.jpg"
              }).then(() => {
                // Profile updated!
                navigation.replace('Chat')
                // ...
              }).catch((error) => {
                // An error occurred
                // ...
              });
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(error)
            // ..
          });
    }

    return (
        <View style={style.container}>
            <TextInput
                style={style.input}
                onChangeText={setName}
                value={name}
                placeholder="Name"
            />
            <TextInput
                style={style.input}
                onChangeText={setEmail}
                value={email}
                placeholder="Email"
            />
            <TextInput
                style={style.input}
                onChangeText={setPassword}
                value={password}
                placeholder="Password"
                secureTextEntry
            />
            <TextInput
                style={style.input}
                onChangeText={setAvatar}
                value={avatar}
                placeholder="Avatar URL"
            />
            <TouchableOpacity
                style={style.button}
                onPress={() => register()}
            >
                <Text style={{ color: 'white' }}>Signup</Text>
            </TouchableOpacity>
        </View>
    )
}

export default SignupScreen;

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        padding: 10
    },
    button: {
        width: 200,
        marginTop: 10,
        padding: 10,
        alignItems: 'center',
        backgroundColor: "#841584"
    },
    input: {
        height: 40,
        width: 300,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    }
})