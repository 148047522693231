import React from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; 

const Button = ( props ) => (
    <View>
        <TouchableOpacity
            onPress={props.onPress}
            style={[{ backgroundColor: props.backgroundColor },
            props.style,
            styles.button
            ]}
        >
            <FontAwesome name={props.iconName} size={24} color="black" />
        </TouchableOpacity>
    </View>
);

export default Button;

const styles = StyleSheet.create({
    button:{
        alignItems:'center',
        justifyContent: 'center',
        borderRadius: 100,
        padding: 10,
        height: 60,
        width: 60
    }
})
